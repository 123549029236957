

export default {
  components: {
  },
  emits: ['update', 'citySelected', 'bodyUpdate'],
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    language: {
      type: String,
      required: false,
      default: 'en',
    },
    id: {
      type: String,
      required: false,
      default: () => `_EL${Math.random().toString().replace('.', '')}`,
    },
    hint: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.autoFocIfNecessary()
      this.separatePhoneBodyAndPrefix()
    },
    value() {
      this.separatePhoneBodyAndPrefix()
    },
    type() {
      this.autoFocIfNecessary()
      this.separatePhoneBodyAndPrefix()
    },
  },

  mounted() {
    this.autoFocIfNecessary()
    this.separatePhoneBodyAndPrefix()
  },

  created() {
    this.focusHandler = e => {
      const { target } = e

      if (target instanceof HTMLElement) {
        if (target.closest('.f_dont-close')) {
          return
        }
      }

      this.searchQuery = ''
      this.openCountrySearch = false
      this.openCitySearchMenu = false
    }

    window.addEventListener('click', this.focusHandler, true)
    window.addEventListener('focus', this.focusHandler, true)
  },

  destroyed() {
    window.removeEventListener('click', this.focusHandler, true)
    window.removeEventListener('focus', this.focusHandler, true)
  },

  data() {
    return {
      citySearchTimeout: null,
      searchedLocations: [],
      focusHandler: null,
      openCountrySearch: false,
      openCitySearchMenu: false,
      prefix: '+39',
      body: '',
      searchQuery: '',
      prefixes: {
        '+93': {
          en: 'Afghanistan',
          it: 'Afghanistan',
          countryCode: 'af',
        },
        '+355': {
          en: 'Albania',
          it: 'Albania',
          countryCode: 'al',
        },
        '+213': {
          en: 'Algeria',
          it: 'Algeria',
          countryCode: 'dz',
        },
        '+1684': {
          en: 'American Samoa',
          it: 'Samoa americane',
          countryCode: 'as',
        },
        '+376': {
          en: 'Andorra',
          it: 'Andorra',
          countryCode: 'ad',
        },
        '+244': {
          en: 'Angola',
          it: 'L\'Angola',
          countryCode: 'ao',
        },
        '+1264': {
          en: 'Anguilla',
          it: 'Anguilla',
          countryCode: 'ai',
        },
        '+672': {
          en: 'Antarctica',
          it: 'Antartide',
          countryCode: 'aq',
        },
        '+1268': {
          en: 'Antigua and Barbuda',
          it: 'Antigua e Barbuda',
          countryCode: 'ag',
        },
        '+54': {
          en: 'Argentina',
          it: 'Argentina',
          countryCode: 'ar',
        },
        '+374': {
          en: 'Armenia',
          it: 'Armenia',
          countryCode: 'am',
        },
        '+297': {
          en: 'Aruba',
          it: 'Aruba',
          countryCode: 'aw',
        },
        '+61': {
          en: 'Cocos Islands',
          it: 'Isole Cocos',
          countryCode: 'cc',
        },
        '+43': {
          en: 'Austria',
          it: 'Austria',
          countryCode: 'at',
        },
        '+994': {
          en: 'Azerbaijan',
          it: 'Azerbaigian',
          countryCode: 'az',
        },
        '+1242': {
          en: 'Bahamas',
          it: 'Bahamas',
          countryCode: 'bs',
        },
        '+973': {
          en: 'Bahrain',
          it: 'Bahrein',
          countryCode: 'bh',
        },
        '+880': {
          en: 'Bangladesh',
          it: 'Bangladesh',
          countryCode: 'bd',
        },
        '+1246': {
          en: 'Barbados',
          it: 'Barbados',
          countryCode: 'bb',
        },
        '+375': {
          en: 'Belarus',
          it: 'Bielorussia',
          countryCode: 'by',
        },
        '+32': {
          en: 'Belgium',
          it: 'Belgio',
          countryCode: 'be',
        },
        '+501': {
          en: 'Belize',
          it: 'Belize',
          countryCode: 'bz',
        },
        '+229': {
          en: 'Benin',
          it: 'Benin',
          countryCode: 'bj',
        },
        '+1441': {
          en: 'Bermuda',
          it: 'Bermuda',
          countryCode: 'bm',
        },
        '+975': {
          en: 'Bhutan',
          it: 'Bhutan',
          countryCode: 'bt',
        },
        '+591': {
          en: 'Bolivia',
          it: 'Bolivia',
          countryCode: 'bo',
        },
        '+387': {
          en: 'Bosnia and Herzegovina',
          it: 'Bosnia Erzegovina',
          countryCode: 'ba',
        },
        '+267': {
          en: 'Botswana',
          it: 'Botswana',
          countryCode: 'bw',
        },
        '+55': {
          en: 'Brazil',
          it: 'Brasile',
          countryCode: 'br',
        },
        '+246': {
          en: 'British Indian Ocean Territory',
          it: 'Territorio britannico dell\'Oceano Indiano',
          countryCode: 'io',
        },
        '+1284': {
          en: 'British Virgin Islands',
          it: 'Isole Vergini Britanniche',
          countryCode: 'vg',
        },
        '+673': {
          en: 'Brunei',
          it: 'Brunei',
          countryCode: 'bn',
        },
        '+359': {
          en: 'Bulgaria',
          it: 'Bulgaria',
          countryCode: 'bg',
        },
        '+226': {
          en: 'Burkina Faso',
          it: 'Burkina Faso',
          countryCode: 'bf',
        },
        '+257': {
          en: 'Burundi',
          it: 'Burundi',
          countryCode: 'bi',
        },
        '+855': {
          en: 'Cambodia',
          it: 'Cambogia',
          countryCode: 'kh',
        },
        '+237': {
          en: 'Cameroon',
          it: 'Camerun',
          countryCode: 'cm',
        },
        '+1': {
          en: 'United States',
          it: 'Stati Uniti',
          countryCode: 'us',
        },
        '+238': {
          en: 'Cape Verde',
          it: 'Capo Verde',
          countryCode: 'cv',
        },
        '+1345': {
          en: 'Cayman Islands',
          it: 'Isole Cayman',
          countryCode: 'ky',
        },
        '+236': {
          en: 'Central African Republic',
          it: 'Repubblica Centrafricana',
          countryCode: 'cf',
        },
        '+235': {
          en: 'Chad',
          it: 'Chad',
          countryCode: 'td',
        },
        '+56': {
          en: 'Chile',
          it: 'Chile',
          countryCode: 'cl',
        },
        '+86': {
          en: 'China',
          it: 'Cina',
          countryCode: 'cn',
        },
        '+57': {
          en: 'Colombia',
          it: 'Colombia',
          countryCode: 'co',
        },
        '+269': {
          en: 'Comoros',
          it: 'Comore',
          countryCode: 'km',
        },
        '+682': {
          en: 'Cook Islands',
          it: 'Isole Cook',
          countryCode: 'ck',
        },
        '+506': {
          en: 'Costa Rica',
          it: 'Costa Rica',
          countryCode: 'cr',
        },
        '+385': {
          en: 'Croatia',
          it: 'Croazia',
          countryCode: 'hr',
        },
        '+53': {
          en: 'Cuba',
          it: 'Cuba',
          countryCode: 'cu',
        },
        '+357': {
          en: 'Cyprus',
          it: 'Cipro',
          countryCode: 'cy',
        },
        '+420': {
          en: 'Czech Republic',
          it: 'Repubblica Ceca',
          countryCode: 'cz',
        },
        '+243': {
          en: 'Democratic Republic of the Congo',
          it: 'Repubblica Democratica del Congo',
          countryCode: 'cd',
        },
        '+45': {
          en: 'Denmark',
          it: 'Danimarca',
          countryCode: 'dk',
        },
        '+253': {
          en: 'Djibouti',
          it: 'Gibuti',
          countryCode: 'dj',
        },
        '+1767': {
          en: 'Dominica',
          it: 'Dominica',
          countryCode: 'dm',
        },
        '+1809': {
          en: 'Dominican Republic',
          it: 'Repubblica Dominicana',
          countryCode: 'do',
        },
        '+1829': {
          en: 'Dominican Republic',
          it: 'Repubblica Dominicana',
          countryCode: 'do',
        },
        '+1849': {
          en: 'Dominican Republic',
          it: 'Repubblica Dominicana',
          countryCode: 'do',
        },
        '+670': {
          en: 'East Timor',
          it: 'Timor Est',
          countryCode: 'tl',
        },
        '+593': {
          en: 'Ecuador',
          it: 'Ecuador',
          countryCode: 'ec',
        },
        '+20': {
          en: 'Egypt',
          it: 'Egitto',
          countryCode: 'eg',
        },
        '+503': {
          en: 'El Salvador',
          it: 'Il salvatore',
          countryCode: 'sv',
        },
        '+240': {
          en: 'Equatorial Guinea',
          it: 'Guinea Equatoriale',
          countryCode: 'gq',
        },
        '+291': {
          en: 'Eritrea',
          it: 'Eritrea',
          countryCode: 'er',
        },
        '+372': {
          en: 'Estonia',
          it: 'Etiopia',
          countryCode: 'ee',
        },
        '+251': {
          en: 'Ethiopia',
          it: 'Ethiopia',
          countryCode: 'et',
        },
        '+500': {
          en: 'Falkland Islands',
          it: 'Isole Falkland',
          countryCode: 'fk',
        },
        '+298': {
          en: 'Faroe Islands',
          it: 'Isole Faroe',
          countryCode: 'fo',
        },
        '+679': {
          en: 'Fiji',
          it: 'Figi',
          countryCode: 'fj',
        },
        '+358': {
          en: 'Finland',
          it: 'Finlandia',
          countryCode: 'fi',
        },
        '+33': {
          en: 'France',
          it: 'Francia',
          countryCode: 'fr',
        },
        '+689': {
          en: 'French Polynesia',
          it: 'Polinesia francese',
          countryCode: 'pf',
        },
        '+241': {
          en: 'Gabon',
          it: 'Gabon',
          countryCode: 'ga',
        },
        '+220': {
          en: 'Gambia',
          it: 'Gambia',
          countryCode: 'gm',
        },
        '+995': {
          en: 'Georgia',
          it: 'Georgia',
          countryCode: 'ge',
        },
        '+49': {
          en: 'Germany',
          it: 'Germania',
          countryCode: 'de',
        },
        '+233': {
          en: 'Ghana',
          it: 'Ghana',
          countryCode: 'gh',
        },
        '+350': {
          en: 'Gibraltar',
          it: 'Gibilterra',
          countryCode: 'gi',
        },
        '+30': {
          en: 'Greece',
          it: 'Grecia',
          countryCode: 'gr',
        },
        '+299': {
          en: 'Greenland',
          it: 'Groenlandia',
          countryCode: 'gl',
        },
        '+1473': {
          en: 'Grenada',
          it: 'Grenada',
          countryCode: 'gd',
        },
        '+1671': {
          en: 'Guam',
          it: 'Guam',
          countryCode: 'gu',
        },
        '+502': {
          en: 'Guatemala',
          it: 'Guatemala',
          countryCode: 'gt',
        },
        '+441481': {
          en: 'Guernsey',
          it: 'Guernsey',
          countryCode: 'gg',
        },
        '+224': {
          en: 'Guinea',
          it: 'Guinea',
          countryCode: 'gn',
        },
        '+245': {
          en: 'Guinea-Bissau',
          it: 'Guinea-Bissau',
          countryCode: 'gw',
        },
        '+592': {
          en: 'Guyana',
          it: 'Guyana',
          countryCode: 'gy',
        },
        '+509': {
          en: 'Haiti',
          it: 'Haiti',
          countryCode: 'ht',
        },
        '+504': {
          en: 'Honduras',
          it: 'Honduras',
          countryCode: 'hn',
        },
        '+852': {
          en: 'Hong Kong',
          it: 'Hong Kong',
          countryCode: 'hk',
        },
        '+36': {
          en: 'Hungary',
          it: 'Ungheria',
          countryCode: 'hu',
        },
        '+354': {
          en: 'Iceland',
          it: 'Islanda',
          countryCode: 'is',
        },
        '+91': {
          en: 'India',
          it: 'India',
          countryCode: 'in',
        },
        '+62': {
          en: 'Indonesia',
          it: 'Indonesia',
          countryCode: 'id',
        },
        '+98': {
          en: 'Iran',
          it: 'Iran',
          countryCode: 'ir',
        },
        '+964': {
          en: 'Iraq',
          it: 'Iraq',
          countryCode: 'iq',
        },
        '+353': {
          en: 'Ireland',
          it: 'Irlanda',
          countryCode: 'ie',
        },
        '+441624': {
          en: 'Isle of Man',
          it: 'Isola di Man',
          countryCode: 'im',
        },
        '+972': {
          en: 'Israel',
          it: 'Israele',
          countryCode: 'il',
        },
        '+39': {
          en: 'Italy',
          it: 'Italia',
          countryCode: 'it',
        },
        '+225': {
          en: 'Ivory Coast',
          it: 'Costa d\'Avorio',
          countryCode: 'ci',
        },
        '+1876': {
          en: 'Jamaica',
          it: 'Giamaica',
          countryCode: 'jm',
        },
        '+81': {
          en: 'Japan',
          it: 'Giappone',
          countryCode: 'jp',
        },
        '+441534': {
          en: 'Jersey',
          it: 'Jersey',
          countryCode: 'je',
        },
        '+962': {
          en: 'Jordan',
          it: 'Giordania',
          countryCode: 'jo',
        },
        '+7': {
          en: 'Russia',
          it: 'Russia',
          countryCode: 'ru',
        },
        '+254': {
          en: 'Kenya',
          it: 'Kenia',
          countryCode: 'ke',
        },
        '+686': {
          en: 'Kiribati',
          it: 'Kiribati',
          countryCode: 'ki',
        },
        '+383': {
          en: 'Kosovo',
          it: 'Kosovo',
          countryCode: 'xk',
        },
        '+965': {
          en: 'Kuwait',
          it: 'Kuwait',
          countryCode: 'kw',
        },
        '+996': {
          en: 'Kyrgyzstan',
          it: 'Kirghizistan',
          countryCode: 'kg',
        },
        '+856': {
          en: 'Laos',
          it: 'Laos',
          countryCode: 'la',
        },
        '+371': {
          en: 'Latvia',
          it: 'Lettonia',
          countryCode: 'lv',
        },
        '+961': {
          en: 'Lebanon',
          it: 'Libano',
          countryCode: 'lb',
        },
        '+266': {
          en: 'Lesotho',
          it: 'Lesoto',
          countryCode: 'ls',
        },
        '+231': {
          en: 'Liberia',
          it: 'Liberia',
          countryCode: 'lr',
        },
        '+218': {
          en: 'Libya',
          it: 'Libia',
          countryCode: 'ly',
        },
        '+423': {
          en: 'Liechtenstein',
          it: 'Liechtenstein',
          countryCode: 'li',
        },
        '+370': {
          en: 'Lithuania',
          it: 'Lituania',
          countryCode: 'lt',
        },
        '+352': {
          en: 'Luxembourg',
          it: 'Lussemburgo',
          countryCode: 'lu',
        },
        '+853': {
          en: 'Macau',
          it: 'Macao',
          countryCode: 'mo',
        },
        '+389': {
          en: 'Macedonia',
          it: 'Macedonia',
          countryCode: 'mk',
        },
        '+261': {
          en: 'Madagascar',
          it: 'Madagascar',
          countryCode: 'mg',
        },
        '+265': {
          en: 'Malawi',
          it: 'Malawi',
          countryCode: 'mw',
        },
        '+60': {
          en: 'Malaysia',
          it: 'Malaysia',
          countryCode: 'my',
        },
        '+960': {
          en: 'Maldives',
          it: 'Maldive',
          countryCode: 'mv',
        },
        '+223': {
          en: 'Mali',
          it: 'Mali',
          countryCode: 'ml',
        },
        '+356': {
          en: 'Malta',
          it: 'Malta',
          countryCode: 'mt',
        },
        '+692': {
          en: 'Marshall Islands',
          it: 'Isole Marshall',
          countryCode: 'mh',
        },
        '+222': {
          en: 'Mauritania',
          it: 'Mauritania',
          countryCode: 'mr',
        },
        '+230': {
          en: 'Mauritius',
          it: 'Maurizio',
          countryCode: 'mu',
        },
        '+262': {
          en: 'Reunion',
          it: 'Riunione',
          countryCode: 're',
        },
        '+52': {
          en: 'Mexico',
          it: 'Messico',
          countryCode: 'mx',
        },
        '+691': {
          en: 'Micronesia',
          it: 'Micronesia',
          countryCode: 'fm',
        },
        '+373': {
          en: 'Moldova',
          it: 'Moldavia',
          countryCode: 'md',
        },
        '+377': {
          en: 'Monaco',
          it: 'Monaco',
          countryCode: 'mc',
        },
        '+976': {
          en: 'Mongolia',
          it: 'Mongolia',
          countryCode: 'mn',
        },
        '+382': {
          en: 'Montenegro',
          it: 'Montenegro',
          countryCode: 'me',
        },
        '+1664': {
          en: 'Montserrat',
          it: 'Montserrat',
          countryCode: 'ms',
        },
        '+212': {
          en: 'Western Sahara',
          it: 'Sahara occidentale',
          countryCode: 'eh',
        },
        '+258': {
          en: 'Mozambique',
          it: 'Mozambico',
          countryCode: 'mz',
        },
        '+95': {
          en: 'Myanmar',
          it: 'Myanmar',
          countryCode: 'mm',
        },
        '+264': {
          en: 'Namibia',
          it: 'Namibia',
          countryCode: 'na',
        },
        '+674': {
          en: 'Nauru',
          it: 'Nauru',
          countryCode: 'nr',
        },
        '+977': {
          en: 'Nepal',
          it: 'Nepal',
          countryCode: 'np',
        },
        '+31': {
          en: 'Netherlands',
          it: 'Olanda',
          countryCode: 'nl',
        },
        '+687': {
          en: 'New Caledonia',
          it: 'Nuova Caledonia',
          countryCode: 'nc',
        },
        '+64': {
          en: 'Pitcairn',
          it: 'Pitcairn',
          countryCode: 'pn',
        },
        '+505': {
          en: 'Nicaragua',
          it: 'Nicaragua',
          countryCode: 'ni',
        },
        '+227': {
          en: 'Niger',
          it: 'Niger',
          countryCode: 'ne',
        },
        '+234': {
          en: 'Nigeria',
          it: 'Nigeria',
          countryCode: 'ng',
        },
        '+683': {
          en: 'Niue',
          it: 'Niue',
          countryCode: 'nu',
        },
        '+850': {
          en: 'North Korea',
          it: 'Corea del nord',
          countryCode: 'kp',
        },
        '+1670': {
          en: 'Northern Mariana Islands',
          it: 'Isole Marianne settentrionali',
          countryCode: 'mp',
        },
        '+47': {
          en: 'Svalbard and Jan Mayen',
          it: 'Svalbard e Jan Mayen',
          countryCode: 'sj',
        },
        '+968': {
          en: 'Oman',
          it: 'Oman',
          countryCode: 'om',
        },
        '+92': {
          en: 'Pakistan',
          it: 'Pakistan',
          countryCode: 'pk',
        },
        '+680': {
          en: 'Palau',
          it: 'Palau',
          countryCode: 'pw',
        },
        '+970': {
          en: 'Palestine',
          it: 'Palestina',
          countryCode: 'ps',
        },
        '+507': {
          en: 'Panama',
          it: 'Panama',
          countryCode: 'pa',
        },
        '+675': {
          en: 'Papua New Guinea',
          it: 'Papua Nuova Guinea',
          countryCode: 'pg',
        },
        '+595': {
          en: 'Paraguay',
          it: 'Paraguay',
          countryCode: 'py',
        },
        '+51': {
          en: 'Peru',
          it: 'Perù',
          countryCode: 'pe',
        },
        '+63': {
          en: 'Philippines',
          it: 'Filippine',
          countryCode: 'ph',
        },
        '+48': {
          en: 'Poland',
          it: 'Polonia',
          countryCode: 'pl',
        },
        '+351': {
          en: 'Portugal',
          it: 'Portogallo',
          countryCode: 'pt',
        },
        '+1787, 1-939': {
          en: 'Puerto Rico',
          it: 'Porto Rico',
          countryCode: 'pr',
        },
        '+974': {
          en: 'Qatar',
          it: 'Qatar',
          countryCode: 'qa',
        },
        '+242': {
          en: 'Republic of the Congo',
          it: 'Repubblica del Congo',
          countryCode: 'cg',
        },
        '+40': {
          en: 'Romania',
          it: 'Romania',
          countryCode: 'ro',
        },
        '+250': {
          en: 'Rwanda',
          it: 'Ruanda',
          countryCode: 'rw',
        },
        '+590': {
          en: 'Saint Martin',
          it: 'San Martino',
          countryCode: 'mf',
        },
        '+290': {
          en: 'Saint Helena',
          it: 'Sant\'Elena',
          countryCode: 'sh',
        },
        '+1869': {
          en: 'Saint Kitts and Nevis',
          it: 'Saint Kitts e Nevis',
          countryCode: 'kn',
        },
        '+1758': {
          en: 'Saint Lucia',
          it: 'Santa Lucia',
          countryCode: 'lc',
        },
        '+508': {
          en: 'Saint Pierre and Miquelon',
          it: 'Saint Pierre e Miquelon',
          countryCode: 'pm',
        },
        '+1784': {
          en: 'Saint Vincent and the Grenadines',
          it: 'Saint Vincent e Grenadine',
          countryCode: 'vc',
        },
        '+685': {
          en: 'Samoa',
          it: 'Samoa',
          countryCode: 'ws',
        },
        '+378': {
          en: 'San Marino',
          it: 'San Marino',
          countryCode: 'sm',
        },
        '+239': {
          en: 'Sao Tome and Principe',
          it: 'Sao Tomé e Principe',
          countryCode: 'st',
        },
        '+966': {
          en: 'Saudi Arabia',
          it: 'Arabia Saudita',
          countryCode: 'sa',
        },
        '+221': {
          en: 'Senegal',
          it: 'Senegal',
          countryCode: 'sn',
        },
        '+381': {
          en: 'Serbia',
          it: 'Serbia',
          countryCode: 'rs',
        },
        '+248': {
          en: 'Seychelles',
          it: 'Seychelles',
          countryCode: 'sc',
        },
        '+232': {
          en: 'Sierra Leone',
          it: 'Sierra Leone',
          countryCode: 'sl',
        },
        '+65': {
          en: 'Singapore',
          it: 'Singapore',
          countryCode: 'sg',
        },
        '+1721': {
          en: 'Sint Maarten',
          it: 'Sint Maarten',
          countryCode: 'sx',
        },
        '+421': {
          en: 'Slovakia',
          it: 'Slovacchia',
          countryCode: 'sk',
        },
        '+386': {
          en: 'Slovenia',
          it: 'Slovenia',
          countryCode: 'si',
        },
        '+677': {
          en: 'Solomon Islands',
          it: 'Isole Salomone',
          countryCode: 'sb',
        },
        '+252': {
          en: 'Somalia',
          it: 'Somalia',
          countryCode: 'so',
        },
        '+27': {
          en: 'South Africa',
          it: 'Sud Africa',
          countryCode: 'za',
        },
        '+82': {
          en: 'South Korea',
          it: 'Corea del Sud',
          countryCode: 'kr',
        },
        '+211': {
          en: 'South Sudan',
          it: 'Sudan del Sud',
          countryCode: 'ss',
        },
        '+34': {
          en: 'Spain',
          it: 'Spagna',
          countryCode: 'es',
        },
        '+94': {
          en: 'Sri Lanka',
          it: 'Sri Lanka',
          countryCode: 'lk',
        },
        '+249': {
          en: 'Sudan',
          it: 'Sudan',
          countryCode: 'sd',
        },
        '+597': {
          en: 'Suriname',
          it: 'Suriname',
          countryCode: 'sr',
        },
        '+268': {
          en: 'Swaziland',
          it: 'Swaziland',
          countryCode: 'sz',
        },
        '+46': {
          en: 'Sweden',
          it: 'Svezia',
          countryCode: 'se',
        },
        '+41': {
          en: 'Switzerland',
          it: 'Svizzera',
          countryCode: 'ch',
        },
        '+963': {
          en: 'Syria',
          it: 'Siria',
          countryCode: 'sy',
        },
        '+886': {
          en: 'Taiwan',
          it: 'Taiwan',
          countryCode: 'tw',
        },
        '+992': {
          en: 'Tajikistan',
          it: 'Tagikistan',
          countryCode: 'tj',
        },
        '+255': {
          en: 'Tanzania',
          it: 'Tanzania',
          countryCode: 'tz',
        },
        '+66': {
          en: 'Thailand',
          it: 'Tailandia',
          countryCode: 'th',
        },
        '+228': {
          en: 'Togo',
          it: 'Togo',
          countryCode: 'tg',
        },
        '+690': {
          en: 'Tokelau',
          it: 'Tokelau',
          countryCode: 'tk',
        },
        '+676': {
          en: 'Tonga',
          it: 'Tonga',
          countryCode: 'to',
        },
        '+1868': {
          en: 'Trinidad and Tobago',
          it: 'Trinidad e Tobago',
          countryCode: 'tt',
        },
        '+216': {
          en: 'Tunisia',
          it: 'Tunisia',
          countryCode: 'tn',
        },
        '+90': {
          en: 'Turkey',
          it: 'Turchia',
          countryCode: 'tr',
        },
        '+993': {
          en: 'Turkmenistan',
          it: 'Isole Turks e Caicos',
          countryCode: 'tm',
        },
        '+1649': {
          en: 'Turks and Caicos Islands',
          it: 'Turks and Caicos Islands',
          countryCode: 'tc',
        },
        '+688': {
          en: 'Tuvalu',
          it: 'Tuvalu',
          countryCode: 'tv',
        },
        '+1340': {
          en: 'U.S. Virgin Islands',
          it: 'Isole Vergini americane',
          countryCode: 'vi',
        },
        '+256': {
          en: 'Uganda',
          it: 'Uganda',
          countryCode: 'ug',
        },
        '+380': {
          en: 'Ukraine',
          it: 'Ucraina',
          countryCode: 'ua',
        },
        '+971': {
          en: 'United Arab Emirates',
          it: 'Emirati Arabi Uniti',
          countryCode: 'ae',
        },
        '+44': {
          en: 'United Kingdom',
          it: 'Regno Unito',
          countryCode: 'gb',
        },
        '+598': {
          en: 'Uruguay',
          it: 'Uruguay',
          countryCode: 'uy',
        },
        '+998': {
          en: 'Uzbekistan',
          it: 'Uzbekistan',
          countryCode: 'uz',
        },
        '+678': {
          en: 'Vanuatu',
          it: 'Vanuatu',
          countryCode: 'vu',
        },
        '+379': {
          en: 'Vatican',
          it: 'Vaticano',
          countryCode: 'va',
        },
        '+58': {
          en: 'Venezuela',
          it: 'Venezuela',
          countryCode: 've',
        },
        '+84': {
          en: 'Vietnam',
          it: 'Vietnam',
          countryCode: 'vn',
        },
        '+681': {
          en: 'Wallis and Futuna',
          it: 'Wallis e Futuna',
          countryCode: 'wf',
        },
        '+967': {
          en: 'Yemen',
          it: 'Yemen',
          countryCode: 'ye',
        },
        '+260': {
          en: 'Zambia',
          it: 'Zambia',
          countryCode: 'zm',
        },
        '+263': {
          en: 'Zimbabwe',
          it: 'Zimbabwe',
          countryCode: 'zw',
        },
      },
    }
  },

  computed: {
    searchResults() {
      const result = []
      const lowercase = this.searchQuery?.toLowerCase() ?? ''

      for (const prefix in this.prefixes) {
        const data = this.prefixes[prefix]

        result.push({
          show: prefix.toLowerCase().includes(lowercase) || data[this.language]?.toLowerCase().includes(lowercase),
          prefix,
          name: data[this.language],
          countryCode: data.countryCode,
        })
      }

      const mainCountries = ['it', 'ch', 'es', 'fr', 'de']

      result.sort((a, b) => {
        const mainIndexA = mainCountries.indexOf(a.countryCode)
        const mainIndexB = mainCountries.indexOf(b.countryCode)

        if (mainIndexA > -1 && mainIndexB > -1) {
          return mainIndexA - mainIndexB
        }

        if (mainIndexA === -1 && mainIndexB > -1) {
          return 1
        }

        if (mainIndexA > -1 && mainIndexB === -1) {
          return -1
        }

        return a.name < b.name ? -1 : 1
      })

      return result
    },
  },

  methods: {
    separatePhoneBodyAndPrefix() {
      if (this.type === 'tel') {
        console.log(4545, this.value)

        for (const prefix in this.prefixes) {
          if (this.value.startsWith(prefix)) {
            this.prefix = prefix
            this.body = this.value.substr(this.prefix.length)

            return
          }
        }

        this.body = this.body.replace(/[+]+/gm, '')
      }
    },

    getImgUrl(prefix) {
      const countryCode = this.prefixes[prefix]?.countryCode ?? 'it'
      return require(`@/assets/images/icons/country-straight/${countryCode}.png`)
    },

    updatePrefix(prefix) {
      this.prefix = prefix
      this.openCountrySearch = false
      this.searchQuery = ''
      this.$emit('update', this.prefix + this.body)
      this.$emit('bodyUpdate', this.body)
    },

    openMenu() {
      if (this.openCountrySearch) {
        this.searchQuery = ''
        this.openCountrySearch = false

        return
      }

      this.openCountrySearch = true

      setTimeout(() => {
        this.$refs.search?.focus()
      }, 100)
    },

    selectOption(value) {
      this.$emit('update', value)
    },

    onClick() {
      this.openCitySearchMenu = this.type === 'city'

      if (this.openCitySearchMenu) {
        this.$refs.input?.dispatchEvent(new Event('input'))
      }
    },

    onEnter(e) {
      e.preventDefault()
      e.target?.closest('form')?.dispatchEvent(new Event('submit'))
    },

    onInput(e) {
      if (this.type === 'tel') {
        e.target.value = e.target.value.replace(/\D/gm, '')
        this.body = e.target.value
        this.$emit('update', this.prefix + this.body)
        this.$emit('bodyUpdate', this.body)

        return
      }

      if (this.type === 'city') {
        this.searchQuery = e.target.value
        this.openCitySearchMenu = true

        if (this.citySearchTimeout !== null) {
          clearTimeout(this.citySearchTimeout)
        }

        this.citySearchTimeout = setTimeout(() => {
          this.searchCity(this.searchQuery)
          this.citySearchTimeout = null
        }, 500)
      }

      if (this.type === 'number') {
        e.target.value = e.target.value.replace(/\D/gm, '')
        if (e.target.valueAsNumber < 1) {
          e.target.valueAsNumber = 1
        }

        e.target.valueAsNumber = Math.min(e.target.valueAsNumber, 10)
      }

      this.$emit('update', e.target.value)
    },

    updateNumber(increment = false) {
      this.$refs.input.valueAsNumber = this.$refs.input.valueAsNumber ? this.$refs.input.valueAsNumber : 0
      increment ? this.$refs.input.valueAsNumber++ : this.$refs.input.valueAsNumber--
      this.$refs.input.valueAsNumber = Math.min(this.$refs.input.valueAsNumber, 10)
      this.$emit('update', this.$refs.input.value)
    },

    async chooseCity(cityData) {
      this.openCitySearchMenu = false
      const validCity = cityData.province ? `${cityData.name} (${cityData.province}), ${cityData.country}` : `${cityData.name}, ${cityData.country}`
      this.$emit('update', validCity)
      this.$emit('citySelected', validCity)
    },

    autoFocIfNecessary() {
      if (this.autofocus) {
        this.$refs.input?.focus()
        setTimeout(() => this.$refs.input?.focus(), 50)
      }
    },

    async searchCity(searchQuery) {
      try {
        if (!searchQuery.trim()) {
          throw new Error('Empty query')
        }

        const result = await fetch(
          `https://nominatim.openstreetmap.org/search?&city=${encodeURIComponent(searchQuery)}&format=json&accept-language=${this.language}&addressdetails=1`,
        )

        const data = await result.json()

        if (!result.ok) {
          throw data
        }

        if (this.searchQuery === searchQuery) {
          const countries = data.map(x => {
            const province = x.address['ISO3166-2-lvl6'] ? x.address['ISO3166-2-lvl6'].replace(/^\w+-/gm, '') : null

            return {
              province,
              country: x.address.country,
              name: x.name,
              description: province ? `(${province}), ${x.address.country}` : x.address.country,
            }
          })

          const map = new Map(countries.map(e => [e.name + e.description, e]))
          this.searchedLocations = [...map.values()]
          console.log(this.searchedLocations)
        }
      } catch (e) {
        console.log(e)

        if (this.searchQuery === searchQuery) {
          this.searchedLocations = []
        }
      }
    },
  },
}

