

import FormInput from '@/views/form/FormInput.vue'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import UrlUtil from '@/utils/UrlUtil'
import { config } from '@/config/config'

export default {

  components: {
    FormInput,
    VueHcaptcha,
  },

  data() {
    return {
      parentListener: null,
      validEmailPromises: new Map(),
      validEmails: new Set(),
      errorEmails: new Set(),
      submitProgress: 0,
      unloadedSubmitProgress: 0,
      showSubmitErrorPopup: false,
      submitting: false,
      submitted: false,
      minStepToRedirect: 'none',
      maxStepToRedirect: 'none',
      currentStep: '',
      language: 'en',
      lastName: '',
      complete: false,
      formErrors: {
        pdfFiles: false,
        pdfFilesMessage: '',
        stepFiles: false,
        stepFilesMessage: '',
        firstName: false,
        firstNameMessage: '',
        lastName: false,
        lastNameMessage: '',
        email: false,
        emailMessage: '',
        phoneNumber: false,
        phoneNumberMessage: '',
        companyName: false,
        companyNameMessage: '',
        residence: false,
        residenceMessage: '',
        linkedinUrl: false,
        linkedinUrlMessage: '',
        area: false,
        areaMessage: '',
        teamParticipation: false,
        teamParticipationMessage: '',
        teamName: false,
        teamNameMessage: '',
        participantCount: false,
        participantCountMessage: '',
        projectName: false,
        projectNameMessage: '',
        shortPresentation: false,
        shortPresentationMessage: '',

        // members
        memberFirstName: false,
        memberFirstNameMessage: '',
        memberLastName: false,
        memberLastNameMessage: '',
        memberEmail: false,
        memberEmailMessage: '',
        memberLinkedin: false,
        memberLinkedinMessage: '',
        memberRole: false,
        memberRoleMessage: '',

        // confirm
        checkedRegulationError: false,
        checkedRegulationErrorMessage: '',
        checkedPrivacyPolicyError: false,
        checkedPrivacyPolicyErrorMessage: '',
        checkedSubmitError: false,
        checkedSubmitErrorMessage: '',
        checkedHumanError: false,
        checkedHumanErrorMessage: '',
      },
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumberBody: '',
        phoneNumber: '',
        companyName: '',
        residence: '',
        selectedResidence: '',
        linkedinUrl: '',
        area: '',
        teamParticipation: '',
        teamName: '',
        participantCount: '',
        projectName: '',
        shortPresentation: '',

        members: {},

        pdfFiles: [],
        stepFiles: [],

        // confirm
        checkedRegulation: false,
        checkedPrivacyPolicy: false,
        checkedSubmit: false,
        checkedHuman: false,
      },
      steps: {
        '': {
          progress: 10,
          nextStep: 'lastName',
          prevStep: '',
        },
        lastName: {
          progress: 15,
          nextStep: 'email',
          prevStep: '',
        },
        email: {
          progress: 20,
          nextStep: 'phoneNumber',
          prevStep: 'lastName',
        },
        phoneNumber: {
          progress: 25,
          nextStep: 'companyName',
          prevStep: 'email',
        },
        companyName: {
          progress: 30,
          nextStep: 'residence',
          prevStep: 'phoneNumber',
        },
        residence: {
          progress: 35,
          nextStep: 'linkedin',
          prevStep: 'companyName',
        },
        linkedin: {
          progress: 40,
          nextStep: 'area',
          prevStep: 'residence',
        },
        area: {
          progress: 45,
          nextStep: 'teamParticipation',
          prevStep: 'linkedin',
        },
        teamParticipation: {
          progress: 50,
          nextStep: 'CHECK_TEAM',
          prevStep: 'area',
        },
        teamName: {
          progress: 55,
          nextStep: 'participantCount',
          prevStep: 'teamParticipation',
        },
        participantCount: {
          progress: 60,
          nextStep: 'CHECK_PARTICIPANT_COUNT',
          prevStep: 'teamName',
        },
        shortPresentation: {
          progress: 80,
          nextStep: 'uploadPresentations',
          prevStep: 'CHECK_TEAM',
        },
        uploadPresentations: {
          progress: 85,
          nextStep: 'upload3DFiles',
          prevStep: 'shortPresentation',
        },
        upload3DFiles: {
          progress: 90,
          nextStep: 'recap',
          prevStep: 'uploadPresentations',
        },
        recap: {
          progress: 95,
          nextStep: 'confirm',
          prevStep: 'upload3DFiles',
        },
        confirm: {
          progress: 120,
          nextStep: 'success',
          prevStep: 'recap',
        },
        success: {
          progress: 120,
          nextStep: 'homePage',
          prevStep: 'confirm',
        },
      },
      translations: {
        in_collaboration_with: {
          en: 'in collaboration with',
          it: 'in collaborazione con',
        },
        back: {
          en: 'Back',
          it: 'Indietro',
        },
        next: {
          en: 'Next',
          it: 'Avanti',
        },
        name: {
          en: 'Name',
          it: 'Nome',
        },
        insert_your_name: {
          en: 'Insert your name',
          it: 'Inserisci il tuo nome',
        },
        surname: {
          en: 'Surname',
          it: 'Cognome',
        },
        insert_your_surname: {
          en: 'Insert your surname',
          it: 'Inserisci il tuo cognome',
        },
        email: {
          en: 'Email',
          it: 'Email',
        },
        insert_your_email: {
          en: 'Insert your email',
          it: 'Inserisci la tua email',
        },
        phone_number: {
          en: 'Phone number',
          it: 'Numero di telefono',
        },
        insert_phone_number: {
          en: 'Insert your phone number',
          it: 'Inserisci il tuo numero di telefono',
        },
        what_is_the_name_company: {
          en: 'What is the name of your company?',
          it: 'Qual è il nome della tua azienda?',
        },
        insert_company_name: {
          en: 'Insert your company name',
          it: 'Inserisci il nome della tua azienda',
        },
        company_name: {
          en: 'Company name',
          it: 'Nome azienda',
        },
        currently_reside: {
          en: 'Currently reside',
          it: 'Attualmente risiedi',
        },
        what_is_the_residence: {
          en: 'Where do you currently reside?',
          it: 'Dove risiedi attualmente?',
        },
        insert_city: {
          en: 'Insert your city',
          it: 'Inserisci la città',
        },
        linkedin_profile: {
          en: 'LinkedIn profile',
          it: 'Profilo LinkedIn',
        },
        linkedin: {
          en: 'LinkedIn',
          it: 'LinkedIn',
        },
        role: {
          en: 'Role',
          it: 'Ruolo',
        },
        insert_the_link_to_your_profile: {
          en: 'Insert the link to your profile',
          it: 'Inserisci il link al tuo profilo',
        },
        which_area_do_you_want_to_apply_for: {
          en: 'Which area do you want to apply for?',
          it: 'Per quale area vuoi candidarti?',
        },
        lighting: {
          en: 'Lighting',
          it: 'Illuminazione',
        },
        products_customization: {
          en: 'Products customization',
          it: 'Customizzazione di prodotti',
        },
        modular_systems: {
          en: 'Modular systems',
          it: 'Sistemi modulari',
        },
        artworks: {
          en: 'Artworks',
          it: 'Opere artistiche',
        },
        do_you_participate_in_team: {
          en: 'Do you participate in team?',
          it: 'Partecipi in team?',
        },
        yes: {
          en: 'Yes',
          it: 'Si',
        },
        no: {
          en: 'No',
          it: 'No',
        },
        team_name: {
          en: 'Team name',
          it: 'Nome del team',
        },
        insert_team_name: {
          en: 'Insert team name',
          it: 'Inserisci il nome del team',
        },
        how_many_will_participate: {
          en: 'How many will participate?',
          it: 'Quanti parteciperanno?',
        },
        write_a_short_presentation: {
          en: 'Write a short presentation of the project',
          it: 'Scrivi una breve presentazione del progetto',
        },
        project_name: {
          en: 'Project name',
          it: 'Nome progetto',
        },
        explain_your_project: {
          en: 'Explain your project in a maximum of 500 characters',
          it: 'Descrivi il tuo progetto in un massimo di 500 caratteri',
        },
        participance_info_text: {
          en: 'Enter the data of the team members within the presentation',
          it: 'Inserisci i dati dei membri del team all\'interno della presentazione.',
        },
        member1: {
          en: 'First member',
          it: 'Primo membro',
        },
        member2: {
          en: 'Second member',
          it: 'Secondo membro',
        },
        member3: {
          en: 'Third member',
          it: 'Terzo membro',
        },
        member4: {
          en: 'Fourth member',
          it: 'Quarto membro',
        },
        member5: {
          en: 'Fifth member',
          it: 'Quinto membro',
        },
        member6: {
          en: 'Sixth member',
          it: 'Sesto membro',
        },
        member7: {
          en: 'Seventh member',
          it: 'Settimo membro',
        },
        member8: {
          en: 'Eighth member',
          it: 'Ottavo membro',
        },
        member9: {
          en: 'Ninth member',
          it: 'Nono membro',
        },
        member10: {
          en: 'Tenth member',
          it: 'Decimo membro',
        },
        upload_concept_presentation: {
          en: 'Upload concept presentation',
          it: 'Carica la presentazione del concept',
        },
        upload_concept_presentation_description: {
          en: 'You can upload more than one file, sketches and illustrative sketches are also accepted.',
          it: 'È possibile caricare più di un file, sono accettati anche schizzi e bozzetti illustrativi.',
        },
        upload_3d_files: {
          en: 'Upload 3D files',
          it: 'Carica i file 3D',
        },
        upload_3d_files_description: {
          en: 'Uploading 3D files, is a good nice to have but is not mandatory.',
          it: 'Il caricamento di file 3D è una buona cosa da fare, ma non è obbligatorio.',
        },
        click_to_upload: {
          en: 'Click to upload',
          it: 'Clicca per caricare',
        },
        or_drag_and_drop: {
          en: 'or drag and drop',
          it: 'o trascina e rilascia',
        },
        loaded: {
          en: 'Loaded',
          it: 'Caricato',
        },
        upload_failed: {
          en: 'Upload failed',
          it: 'Caricamento fallito',
        },
        file_too_large: {
          en: 'File too large',
          it: 'File troppo grande',
        },
        failed: {
          en: 'Failed',
          it: 'Fallito',
        },
        recap_of_your_application: {
          en: 'Recap of your application',
          it: 'Riassunto della candidatura',
        },
        your_personal_data: {
          en: 'Your personal data',
          it: 'I tuoi dati personali',
        },
        your_teams_data: {
          en: 'Your team\'s data',
          it: 'I dati del tuo team',
        },
        number_of_participants: {
          en: 'Number of participants',
          it: 'Numero di partecipanti',
        },
        member: {
          en: 'Member',
          it: 'Membro',
        },
        design_area: {
          en: 'Design area',
          it: 'Area di progettazione',
        },
        do_you_participate_in_teams: {
          en: 'Do you participate in teams?',
          it: 'Partecipi in team?',
        },
        team_name: {
          en: 'Team name',
          it: 'Nome team',
        },
        uploaded_files: {
          en: 'Uploaded files',
          it: 'I file caricati',
        },
        project_details: {
          en: 'Project details',
          it: 'Dettagli del progetto',
        },
        project_description: {
          en: 'Project description',
          it: 'Descrizione del progetto',
        },
        concept_presentation: {
          en: 'Concept presentation',
          it: 'Presentazione del concept',
        },
        '3d_files': {
          en: '3D files',
          it: 'File 3D',
        },
        thank_you_for_sending_us: {
          en: 'Thank you for sending us your application!',
          it: 'Grazie per averci mandato la tua candidatura!',
        },
        you_will_receive_a_confirmation_email: {
          en: 'You will receive a confirmation email',
          it: 'Riceverai una email di conferma',
        },
        submit: {
          en: 'Submit',
          it: 'Invia',
        },
        go_to_homepage: {
          en: 'Go to Homepage',
          it: 'Torna alla Homepage',
        },
        confirm_participation_in_the: {
          en: 'Confirm participation in the',
          it: 'Conferma partecipazione',
        },
        additive_design_contest: {
          en: 'Additive Design Contest',
          it: 'Additive Design Contest',
        },
        i_have_read_and_accept_the: {
          en: 'I have read and accept the ',
          it: 'Ho letto e accetto il ',
        },
        i_have_read_and_accept_the_2: {
          en: 'I have read and accept the ',
          it: 'Ho letto e accetto l’',
        },
        form_submit_text: {
          en: 'By submitting the form, the data provided will be used to process your request and direct marketing activities in compliance with the procedures contained in the',
          it: 'Con l\'invio del modulo, i dati forniti saranno utilizzati per evadere la Sua richiesta e per attività di marketing diretto nel rispetto delle procedure contenute nella',
        },
        privacy_policy: {
          en: 'Privacy Policy',
          it: 'informativa sulla Privacy',
        },
        privacy_policy_2: {
          en: 'Privacy Policy',
          it: 'Privacy Policy',
        },
        regulation: {
          en: 'Regulation',
          it: 'Regolamento',
        },
        i_am_human: {
          en: 'I am human',
          it: 'Sono umano',
        },
        something_went_wrong: {
          en: 'Something went wrong, please try again later',
          it: 'Qualcosa è andato storto, riprova più tardi',
        },
        please_fill_the_capcha: {
          en: 'Please complete the captcha',
          it: 'Per favore completa il captcha',
        },
        please_fill_the_name: {
          en: 'Please fill in the "Name" field',
          it: 'Prego, compilare il campo "Nome"',
        },
        please_fill_the_surname: {
          en: 'Please fill in the "Surname" field',
          it: 'Prego, compilare il campo "Cognome"',
        },
        please_fill_the_email: {
          en: 'Please fill in the "Email" field',
          it: 'Prego, compilare il campo "Email"',
        },
        the_name_must_be_bigger_than_3_characters: {
          en: 'The name must be bigger than 3 characters',
          it: 'Il nome deve essere maggiore di 3 caratteri',
        },
        the_surname_must_be_bigger_than_3_characters: {
          en: 'The surname must be bigger than 3 characters',
          it: 'Il cognome deve essere maggiore di 3 caratteri',
        },
        the_linkedin_link_is_not_valid: {
          en: 'The linkedin link is not valid',
          it: 'Il link di Linkedin non è valido',
        },
        the_email_is_not_valid: {
          en: 'Email not valid, please enter a valid Email to continue with the process',
          it: 'Email non valida, per favore inserire un Email valida per continuare con il processo',
        },
        select_at_least_one_option: {
          en: 'Select at least one option',
          it: 'Selezionare almeno un\'opzione',
        },
        file_missing: {
          en: 'Please update the required file for continue with the process',
          it: 'Prego, caricare i file richiesti per continuare con il processo',
        },
        the_phone_number_should_have_at_least_9_characters: {
          en: 'The phone number should have at least 9 characters',
          it: 'Il numero di telefono deve contenere almeno 9 caratteri',
        },
        the_phone_number_is_not_valid: {
          en: 'The phone number is not valid',
          it: 'Il numero di telefono non è valido',
        },
        the_project_name_must_be_bigger_than_3_characters: {
          en: 'The Project Name must be bigger than 3 characters',
          it: 'Il nome del progetto deve essere maggiore di 3 caratteri',
        },
        please_fill_in_the_project_name_field: {
          en: 'Please fill in the "Project Name" field',
          it: 'Prego, compilare il campo "nome"',
        },
        please_fill_in_the_project_description_field: {
          en: 'Please fill in the "Project Description" field',
          it: 'Prego, compilare il campo "Descrizione del progetto"',
        },
        please_enter_your_current_city_of_residence: {
          en: 'Please enter your current city of residence',
          it: 'Si prega di inserire la città in cui si risiede attualmente',
        },
        please_select_your_current_city_of_residence: {
          en: 'Please select from the list above the city in which you currently reside',
          it: 'Si prega di selezionare dalla lista soprastante la città in cui si risiede',
        },
        company_name_long: {
          en: 'The Company name is too long. Max characters 255.',
          it: 'Il nome dell\'azienda è troppo lungo. Caratteri massimi 255.',
        },
        company_name_invalid: {
          en: 'Your company name entry contains numbers, symbols, or special characters, which are not allowed. Please use only letters (a-z, A-Z) in your entry.',
          it: 'Il nome della tua azienda inserito contiene numeri, simboli o caratteri speciali, che non sono permessi. Si prega di utilizzare solo lettere (a-z, A-Z).',
        },
        select_mandatory_checkbox: {
          en: 'Please select the mandatory checkbox',
          it: 'Prego, selezionare il checkbox obbligatorio',
        },
        role_empty: {
          en: 'Please fill in the "Role" field',
          it: 'Prego, compilare il campo "Ruolo"',
        },
        role_short: {
          en: 'The role must be bigger than 3 characters',
          it: 'Il ruolo deve essere maggiore di 3 caratteri',
        },
        team_name_short: {
          en: 'The team name must be bigger than 3 characters',
          it: 'Il nome del team deve essere maggiore di 3 caratteri',
        },
        team_name_empty: {
          en: 'Please fill in the "Team name" field',
          it: 'Prego, compilare il campo "Nome del team"',
        },
        submit_progress_message: {
          en: 'Please don\'t close this screen, we are uploading your Project to our system',
          it: 'Per favore non chiudere questa schermata, stiamo caricando il tuo progetto nel nostro sistema',
        },
        email_validation_too_many_requests: {
          en: 'Please wait a few minutes before you try again ...',
          it: 'Attendi qualche minuto prima di riprovare...',
        },
      },
    }
  },

  computed: {
    progress() {
      return this.steps[this.currentStep]?.progress ?? 0
    },

    step() {
      return this.$route.query.step ?? ''
    },

    lang() {
      const lang = this.$route.query.lang || 'en'

      if ( ![ 'it', 'en' ].includes( lang ) ) {
        return 'en'
      }

      return lang
    },
  },

  watch: {
    step( step ) {
      console.log( 'Step changes', step )
      this.updateStep( step )
    },

    lang( lang ) {
      console.log( 'Language changes', lang )
      this.language = lang
      location.href = location.href.split( '?lang=' )[0] + '?lang=' + lang + location.href.split( '?lang=' )[1].slice( 2 );
    },
  },

  async mounted() {
    this.language = this.lang
    try {
      await this.$router.replace( { query: { lang: this.language } } );
    } catch ( e ) {
      console.log( 'er->', e )
    }
    setTimeout( () => {
      this.setCookieToHead();
    } )
  },

  created() {
    this.parentListener = e => {
      console.log( 'Got message', e?.data )

      if ( e?.data?.lang && e.data.lang !== this.language ) {
        console.log( 'lang is different' )
        this.updateLanguage( e.data.lang )
      }

      if ( typeof e?.data?.step === 'string' && e.data.step !== this.currentStep ) {
        console.log( 'step is different' )
        this.updateStep( e.data.step )
      }
    }

    window.addEventListener( 'message', this.parentListener )
  },

  destroyed() {
    window.removeEventListener( 'message', this.parentListener )
  },

  methods: {
    openHomePage() {
      if ( window.top.location !== window.self.location ) {
        window.top.location = `https://thedesign.tech/${ this.language }/additive-design-contest`

        return
      }

      document.location.href = `https://thedesign.tech/${ this.language }/additive-design-contest`
    },

    async updateLanguage(lang) {
      try {
        await this.$router.replace({ query: { step: this.currentStep ? this.currentStep : undefined, lang } })
      } catch (e) {
        console.log(e)
      }

      this.sendMessageToParent()
    },

    updateStep( step ) {
      if ( this.validateStepRequest( step ) ) {
        this.currentStep = step
      } else {
        this.navigateToStep( this.currentStep )
      }

      this.sendMessageToParent()
    },

    onUpload( event ) {
      console.log( event )
      const { target } = event
      let files = []

      if ( target instanceof HTMLInputElement ) {
        files = [ ...target.files ]
        target.value = ''
      } else if ( event instanceof DragEvent ) {
        files = [ ...event.dataTransfer.files ]
      }

      if ( this.currentStep === 'uploadPresentations' ) {
        this.formData.pdfFiles = this.formData.pdfFiles.concat( files.filter( file => file.name.toLowerCase().endsWith( '.pdf' ) ) )
      } else {
        this.formData.stepFiles = this.formData.stepFiles.concat( files.filter( file => file.name.toLowerCase().endsWith( '.step' ) ) )
      }
    },

    getSizeText( file ) {
      const b = file.size
      const mb = b / ( 1024 * 1024 )
      const kb = b / 1024

      if ( mb >= 1 ) {
        return `${ parseFloat( mb.toFixed( 1 ) ) }Mb`
      }

      if ( kb >= 1 ) {
        return `${ parseFloat( kb.toFixed( 1 ) ) }kb`
      }

      return `${ b }b`
    },

    async navigateToStep(step) {
      try {
        await this.$router.push({ query: { step, lang: this.language } })
      } catch (e) {
        console.log(e)
      }
    },

    sendMessageToParent() {
      if ( window !== window.parent ) {
        window.parent.postMessage( {
          step: this.currentStep ? this.currentStep : undefined,
          lang: this.language,
          type: 'replace',
        }, '*' )
      }
    },

    async validateFormEmails() {
      if ( this.formData.email ) {
        const status = await this.getMailValidityStatus( this.formData.email )

        if ( status === 'valid' ) {
          this.validEmails.add( this.formData.email )
        } else if ( status === 'error' ) {
          this.errorEmails.add( this.formData.email )
        } else if ( status === 'invalid' ) {
          this.errorEmails.delete( this.formData.email )
        }
      }

      for ( const member of Object.values( this.formData.members ) ) {
        if ( member?.email ) {
          const status = await this.getMailValidityStatus( member.email )

          if ( status === 'valid' ) {
            this.validEmails.add( member.email )
          } else if ( status === 'error' ) {
            this.errorEmails.add( member.email )
          } else if ( status === 'invalid' ) {
            this.errorEmails.delete( member.email )
          }
        }
      }
    },

    async next() {
      console.log( 'Next step' )

      if ( this.currentStep === 'success' ) {
        this.openHomePage()

        return
      }

      let nextStep = this.steps[this.currentStep]?.nextStep ?? ''
      console.log( nextStep )

      if ( nextStep === 'CHECK_TEAM' ) {
        nextStep = this.formData.teamParticipation === 'yes' ? 'teamName' : 'shortPresentation'
      }

      await this.validateFormEmails()

      if ( nextStep === 'CHECK_PARTICIPANT_COUNT' ) {
        const count = +this.formData.participantCount

        if ( count ) {
          nextStep = 'member1'
        } else {
          nextStep = 'shortPresentation'
        }
      }

      if ( this.validateStepRequest( nextStep ) ) {
        if ( nextStep === 'participantCount' && !this.formData.participantCount ) {
          this.updateParticipantCount( '1' )
        }

        if ( this.maxStepToRedirect === nextStep ) {
          nextStep = 'recap'
        }

        if ( nextStep === 'success' && !this.submitted ) {
          await this.submitForm()
          this.complete = true

          return
        }

        await this.navigateToStep( nextStep )
      }
    },

    async sendToEdit( type ) {
      if ( type === 'profile' ) {
        this.minStepToRedirect = ''
        this.maxStepToRedirect = this.formData.teamParticipation === 'yes' ? 'teamParticipation' : 'shortPresentation'
        await this.navigateToStep( '' )
      }

      if ( type === 'teams' ) {
        this.minStepToRedirect = 'teamParticipation'
        this.maxStepToRedirect = 'shortPresentation'
        await this.navigateToStep( this.minStepToRedirect )
      }

      if ( type === 'uploads' ) {
        this.minStepToRedirect = 'shortPresentation'
        this.maxStepToRedirect = 'recap'
        await this.navigateToStep( this.minStepToRedirect )
      }
    },

    async submitForm() {
      if ( this.submitting ) {
        return
      }

      if ( this.submitted ) {
        await this.navigateToStep( 'success' )
      }

      const interval = setInterval(() => {
        this.unloadedSubmitProgress *= 0.9
        this.unloadedSubmitProgress = Math.max(5, this.unloadedSubmitProgress)
      }, 100)

      try {
        this.submitProgress = 0
        this.unloadedSubmitProgress = 5
        this.submitting = true
        this.showSubmitErrorPopup = true
        const totalRequests = this.formData.stepFiles.length + this.formData.pdfFiles.length + 0.05
        let requestsDone = 0

        const requests = [ ...this.formData.stepFiles, ...this.formData.pdfFiles ].map( async file => {
          const fileForm = new FormData()
          if ( file.name.toLowerCase().endsWith( '.step' ) ) {
            fileForm.append( 'file', new File( [ file ], file.name, {
              type: 'application/STEP',
            } ) )
          } else {
            fileForm.append( 'file', file )
          }

          const response = await fetch( `${ config.API_URL }api/user-competition-file`, {
            method: 'post',
            body: fileForm
          } )

          if ( !response.ok ) {
            throw `Failed ti upload file ${ file.name }`
          }

          ++requestsDone
          this.submitProgress = 100 * requestsDone / totalRequests
          this.unloadedSubmitProgress += 100 / totalRequests

          return response
        } )

        const responses = await Promise.all( requests )
        const data = await Promise.all( responses.map( r => r.json() ) )
        const fileIds = data.map( x => x.id )

        function textToApi( fieldValue ) {
          return fieldValue?.trim() ? fieldValue.trim() : undefined
        }

        const body = {
          language: this.language,
          email: textToApi( this.formData.email ),
          firstName: textToApi( this.formData.firstName ),
          lastName: textToApi( this.formData.lastName ),
          companyName: textToApi( this.formData.companyName ),
          cityName: textToApi( this.formData.residence ),
          linkedinUrl: textToApi( this.formData.linkedinUrl ),
          phoneNumber: textToApi( this.formData.phoneNumber ),
          area: {
            lighting: 'Lighting',
            products_customization: 'ProductsCustomization',
            modular_systems: 'ModularSystems',
            artworks: 'Artworks',
          }[this.formData.area],
          team: this.formData.teamParticipation === 'yes' ? {
            name: textToApi( this.formData.teamName ),
            participants: Object.values( this.formData.members ).map( member => ( {
              firstName: textToApi( member.firstName ),
              lastName: textToApi( member.lastName ),
              email: textToApi( member.email ),
              linkedinUrl: textToApi( member.linkedinUrl ),
              role: textToApi( member.role ),
            } ) ),
          } : undefined,
          presentation: {
            name: textToApi( this.formData.projectName ),
            description: textToApi( this.formData.shortPresentation ),
            fileIds,
            stepFileNames: this.formData.stepFiles.map( x => x.name ),
            pdfFileNames: this.formData.pdfFiles.map( x => x.name ),
          },
        }

        const result = await fetch( `${ config.API_URL }api/user-competition`, {
          method: 'post',
          body: JSON.stringify( body ),
          headers: {
            'Content-Type': 'application/json',
          },
        } )

        if ( !result.ok ) {
          throw 'Failed'
        }
      } finally {
        clearInterval(interval)
        this.submitting = false
      }

      this.submitted = true
      this.showSubmitErrorPopup = false
      await this.navigateToStep( 'success' )
    },

    updateParticipantCount( count ) {
      this.formData.participantCount = count
      count = +count

      for ( const step in this.steps ) {
        if ( step.startsWith( 'member' ) ) {
          delete this.steps[step]
          delete this.formData.members[step]
        }
      }

      let progress = 65
      const progressInc = 10 / count

      for ( let i = 1; i <= count; ++i ) {
        this.formData.members[`member${ i }`] = {
          firstName: '',
          lastName: '',
          email: '',
          linkedinUrl: '',
          role: '',
        }

        this.steps[`member${ i }`] = {
          progress,
          nextStep: i === count ? 'shortPresentation' : `member${ i + 1 }`,
          prevStep: i === 1 ? 'participantCount' : `member${ i - 1 }`,
        }

        progress += progressInc
      }
    },

    async back() {
      console.log( 'Prev step' )
      let prevStep = this.steps[this.currentStep]?.prevStep ?? ''
      console.log( prevStep )

      if ( prevStep === 'CHECK_TEAM' ) {
        const count = +this.formData.participantCount
        const participationPrevStep = count ? `member${ this.formData.participantCount }` : 'participantCount'
        prevStep = this.formData.teamParticipation === 'yes' ? participationPrevStep : 'teamParticipation'
      }

      await this.navigateToStep( prevStep )
    },

    isValidLinkedinUrl( url ) {
      if ( UrlUtil.isUrlValid( url ) ) {
        const parsed = new URL( url )
        if ( ( parsed.hostname === 'linkedin.com' || parsed.hostname.endsWith( '.linkedin.com' ) ) && parsed.protocol === 'https:' ) {
          return true
        }
      }

      return false
    },

    getMailValidityStatus( email ) {
      if ( !this.validEmailPromises.has( email ) ) {
        const validateAsynchronously = async () => {
          return email?.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/) ? 'valid' : 'invalid'

          try {
            const result = await fetch( `https://emailaddressvalidation.com/api/p/email/${ email }` )

            if ( !result.ok ) {
              throw 'error'
            }

            const data = await result.json()

            if ( !data.email?.verifiedEmail ) {
              return 'invalid'
            }

            return 'valid'
          } catch ( e ) {
            console.log( e )
            this.validEmailPromises.delete( email )

            return 'error'
          }
        }

        this.validEmailPromises.set( email, validateAsynchronously() )
      }

      return this.validEmailPromises.get( email )
    },

    validateMember( name ) {
      const member = this.formData.members[name]
      console.log( `Validating ${ name }`, member )

      if ( !member ) {
        return false
      }

      let valid = true


      if (member.linkedinUrl && !this.isValidLinkedinUrl(member.linkedinUrl)) {
        this.formErrors.memberLinkedin = true
        this.formErrors.memberLinkedinMessage = 'the_linkedin_link_is_not_valid'
        valid = false
      }

      if ( !( member.firstName?.trim().length > 3 ) ) {
        this.formErrors.memberFirstName = true
        this.formErrors.memberFirstNameMessage = member.firstName?.trim() ? 'the_name_must_be_bigger_than_3_characters' : 'please_fill_the_name'
        valid = false
      }

      if ( !( member.lastName?.trim().length > 3 ) ) {
        this.formErrors.memberLastName = true
        this.formErrors.memberLastNameMessage = member.lastName?.trim() ? 'the_surname_must_be_bigger_than_3_characters' : 'please_fill_the_surname'
        valid = false
      }

      if ( !this.validEmails.has( member.email ) ) {
        this.formErrors.memberEmail = true
        this.formErrors.memberEmailMessage = this.errorEmails.has( member.email ) ? 'email_validation_too_many_requests' : 'the_email_is_not_valid'
        valid = false
      }

      if ( !( member.role?.trim().length > 3 ) ) {
        this.formErrors.memberRole = true
        this.formErrors.memberRoleMessage = member.role?.trim() ? 'role_short' : 'role_empty'

        valid = false
      }

      return valid
    },

    validateStepRequest( step ) {
      console.log( `Validating step ${ step }`, this.currentStep, this.formData )

      if ( this.submitted ) {
        if ( step !== 'success' ) {
          return false
        }
      }

      if ( this.submitting ) {
        return false
      }

      if ( step === this.currentStep ) {
        return true
      }

      for ( const field in this.formErrors ) {
        this.formErrors[field] = false
      }

      switch ( step ) {
        case '':
          return true
        case 'lastName':
          if ( this.formData.firstName?.trim().length > 3 ) {
            return true
          }

          if ( this.formData.firstName?.trim().length ) {
            this.formErrors.firstNameMessage = 'the_name_must_be_bigger_than_3_characters'
          } else {
            this.formErrors.firstNameMessage = 'please_fill_the_name'
          }

          this.formErrors.firstName = true
          break
        case 'email':
          if ( this.formData.lastName?.trim().length > 3 ) {
            return true
          }

          if ( this.formData.lastName?.trim().length ) {
            this.formErrors.lastNameMessage = 'the_surname_must_be_bigger_than_3_characters'
          } else {
            this.formErrors.lastNameMessage = 'please_fill_the_surname'
          }

          this.formErrors.lastName = true
          break
        case 'phoneNumber':
          if ( this.validEmails.has( this.formData.email ) ) {
            return true
          }

          this.formErrors.emailMessage = this.errorEmails.has( this.formData.email ) ? 'email_validation_too_many_requests' : 'the_email_is_not_valid'
          this.formErrors.email = true
          break
        case 'companyName':
          if ( this.formData.phoneNumberBody?.match( /^\d{9,}$/ ) ) {
            if ( new Set( [ ...this.formData.phoneNumberBody ] ).size > 1 ) {
              return true
            }

            this.formErrors.phoneNumberMessage = 'the_phone_number_is_not_valid'
            this.formErrors.phoneNumber = true

            return false
          }

          if ( this.formData.phoneNumberBody?.match( /^\d*$/ ) ) {
            this.formErrors.phoneNumberMessage = 'the_phone_number_should_have_at_least_9_characters'
          } else {
            this.formErrors.phoneNumberMessage = 'the_phone_number_is_not_valid'
          }

          this.formErrors.phoneNumber = true
          break
        case 'residence':
          if ( !this.formData.companyName || this.formData.companyName?.match( /^[\w\s]{0,255}$/ ) ) {
            return true
          }

          if ( this.formData.companyName?.length > 255 ) {
            this.formErrors.companyNameMessage = 'company_name_long'
          } else {
            this.formErrors.companyNameMessage = 'company_name_invalid'
          }

          this.formErrors.companyName = true
          break
        case 'linkedin':
          if ( this.formData.residence?.trim() && this.formData.residence?.trim() === this.formData.selectedResidence?.trim() ) {
            return true
          }

          this.formErrors.residenceMessage = this.formData.residence?.trim() ? 'please_select_your_current_city_of_residence' : 'please_enter_your_current_city_of_residence'
          this.formErrors.residence = true
          break
        case 'area':
          if (!this.formData.linkedinUrl || this.isValidLinkedinUrl(this.formData.linkedinUrl)) {
            return true
          }

          this.formErrors.linkedinUrlMessage = 'the_linkedin_link_is_not_valid'
          this.formErrors.linkedinUrl = true
          break
        case 'teamParticipation':
          if ( this.formData.area ) {
            return true
          }

          this.formErrors.areaMessage = 'select_at_least_one_option'
          this.formErrors.area = true
          break
        case 'teamName':
        case 'shortPresentation':
          if (
            this.formData.teamParticipation === 'yes' && step === 'teamName'
            || this.formData.teamParticipation === 'no' && step === 'shortPresentation'
          ) {
            return true
          }

          if ( this.formData.teamParticipation === 'yes' && step === 'shortPresentation' ) {
            if ( this.formData.participantCount === '' ) {
              this.formErrors.participantCount = true

              return false
            }

            if ( this.formData.participantCount === '0' ) {
              return true
            }

            return this.validateMember( `member${ this.formData.participantCount }` )
          }

          this.formErrors.teamParticipation = true
          this.formErrors.teamParticipationMessage = 'select_at_least_one_option'
          break
        case 'participantCount':
          if ( this.formData.teamName?.trim().length > 3 ) {
            return true
          }

          this.formErrors.teamNameMessage = this.formData.teamName?.trim() ? 'team_name_short' : 'team_name_empty'
          this.formErrors.teamName = true
          break
        case 'uploadPresentations':
          if ( this.formData.shortPresentation?.trim().length && this.formData.projectName?.trim().length > 3 ) {
            return true
          }

          this.formErrors.shortPresentation = !this.formData.shortPresentation?.trim()
          this.formErrors.projectName = !( this.formData.projectName?.trim().length > 3 )

          this.formErrors.projectNameMessage = ( this.formErrors.projectName && this.formData.projectName?.trim() ) ? 'the_project_name_must_be_bigger_than_3_characters' : 'please_fill_in_the_project_name_field'
          this.formErrors.shortPresentationMessage = 'please_fill_in_the_project_description_field'
          break
        case 'upload3DFiles':
          if ( this.formData.pdfFiles.length && !this.formData.pdfFiles.find( f => f.size > 20 * 1024 * 1024 ) ) {
            return true
          }

          if ( this.formData.pdfFiles.length ) {
            this.formErrors.pdfFilesMessage = 'file_too_large'
          } else {
            this.formErrors.pdfFilesMessage = 'file_missing'
          }

          this.formErrors.pdfFiles = true
          break
        case 'recap':
          if ( this.formData.stepFiles.length && !this.formData.stepFiles.find( f => f.size > 100 * 1024 * 1024 ) ) {
            return true
          }

          if ( this.formData.stepFiles.length ) {
            this.formErrors.stepFilesMessage = 'file_too_large'
          } else {
            // this.formErrors.stepFilesMessage = 'file_missing'
          }

          // this.formErrors.stepFiles = true
          // break
          return  true;
        case 'confirm':
          return true
        case 'success':
          if (
            this.formData.checkedRegulation
            && this.formData.checkedPrivacyPolicy
            && this.formData.checkedHuman
          ) {
            return true
          }

          this.formErrors.checkedRegulationError = !this.formData.checkedRegulation
          this.formErrors.checkedPrivacyPolicyError = !this.formData.checkedPrivacyPolicy
          this.formErrors.checkedHumanError = !this.formData.checkedHuman

          this.formErrors.checkedSubmitErrorMessage = 'select_mandatory_checkbox'
          this.formErrors.checkedRegulationErrorMessage = 'select_mandatory_checkbox'
          this.formErrors.checkedPrivacyPolicyErrorMessage = 'select_mandatory_checkbox'
          this.formErrors.checkedHumanErrorMessage = 'select_mandatory_checkbox'

          break
        default:
          if ( step.startsWith( 'member' ) ) {
            if ( this.formData.participantCount === '' ) {
              this.formErrors.participantCount = true

              return false
            }

            if ( !this.steps[step] ) {
              return false
            }

            const lastMemberIndex = +step.replace( 'member', '' )

            if ( lastMemberIndex > 1 ) {
              return this.validateMember( `member${ lastMemberIndex - 1 }` )
            }

            return true
          }
      }

      return false
    },

    tr( snippet, ...params ) {
      const translation = this.translations[snippet]?.[this.language] ?? `[${ this.language }] ${ snippet } not found`
      let i = 0

      return translation.replace( /%+s/gm, match => {
        if ( match.length % 2 === 0 ) {
          match = '%'.repeat( Math.floor( ( match.length - 1 ) / 2 ) ) + ( params[i++] ?? `[${ i }]` )
        } else {
          match = `${ '%'.repeat( Math.floor( ( match.length - 1 ) / 2 ) ) }s`
        }

        return match
      } )
    },

    getCookieImage() {
      // Create anchor element
      let anchorElement = document.createElement( 'a' );

      // Set attributes for the anchor element
      anchorElement.href = '#';
      anchorElement.className = 'iubenda-cs-preferences-link';

      // Create image element
      let imgElement = document.createElement( 'img' );

      // Set attributes for the image element
      imgElement.style.cssText = 'left: 0!important;width: 2rem;background-repeat: no-repeat!important;background-size: 32px 32px!important;background-position: top 0.5px left 1px!important;z-index: 2147483647!important;appearance: none!important;line-height: 34px!important;height: 34px!important;min-width: 34px!important;border-radius: 4px!important;cursor: pointer!important;font-weight: 700!important;font-size: 14px!important;box-shadow: 0 0 0 1px rgba(0,0,0,.15)!important;color: rgba(0,0,0,.65)!important;background-color: #fff!important;display: inline-block!important;vertical-align: middle!important;';
      imgElement.src = 'https://thedesign.tech/fileadmin/user_upload/iubenda-logo.svg';
      imgElement.alt = 'California Consumer Privacy Act (CCPA) Opt-Out Icon';

      // Append the image element to the anchor element
      anchorElement.appendChild( imgElement );
      return anchorElement;
    },
    setCookieToHead() {
      if ( !document?.head?.querySelector( '.form-cookie' ) ) {
        const formCookie = document.createElement( `span` );
        const script1 = document.createElement( `script` );
        script1.type = "text/javascript";
        script1.src = "//cs.iubenda.com/sync/3038061.js";

        const script2 = document.createElement( `script` );
        script2.type = "text/javascript";
        script2.src = "//cdn.iubenda.com/cs/gpp/stub.js";
        const script3 = document.createElement( `script` );
        script3.type = "text/javascript";
        script3.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
        script3.charSet = "UTF-8";
        script3.async = true;

        const script4EN = document.createElement( `script` );
        script4EN.type = "text/javascript";
        script4EN.innerHTML = `
        var _iub = _iub || [];
        _iub.csConfiguration = {
            "askConsentAtCookiePolicyUpdate": true,
            "countryDetection": true,
            "enableFadp": true,
            "enableLgpd": true,
            "enableUspr": true,
            "lang": "en",
            "lgpdAppliesGlobally": false,
            "perPurposeConsent": true,
            "siteId": 3038061,
            "whitelabel": false,
            "cookiePolicyId": 86762028,
            "banner": {
                "acceptButtonDisplay": true,
                "closeButtonDisplay": false,
                "customizeButtonDisplay": true,
                "explicitWithdrawal": true,
                "listPurposes": true,
                "logo": "https://thedesign.tech/fileadmin/user_upload/designtech-logo.svg",
                "position": "float-bottom-left",
                "rejectButtonDisplay": true,
                "showPurposesToggles": true,
                "showTitle": false
            }
};`
        const script4IT = document.createElement( `script` );
        script4IT.type = "text/javascript";
        script4IT.innerHTML = `
        var _iub = _iub || [];
        _iub.csConfiguration = {
            "askConsentAtCookiePolicyUpdate": true,
            "countryDetection": true,
            "enableFadp": true,
            "enableLgpd": true,
            "enableUspr": true,
            "lang": "it",
            "lgpdAppliesGlobally": false,
            "perPurposeConsent": true,
            "siteId": 3038061,
            "whitelabel": false,
            "cookiePolicyId": 81441436,
            "banner": {
                "acceptButtonDisplay": true,
                "closeButtonDisplay": false,
                "customizeButtonDisplay": true,
                "explicitWithdrawal": true,
                "listPurposes": true,
                "logo": "https://thedesign.tech/fileadmin/user_upload/designtech-logo.svg",
                "position": "float-bottom-left",
                "rejectButtonDisplay": true,
                "showPurposesToggles": true,
                "showTitle": false
            }
          };`

        formCookie.classList.add( 'form-cookie' );
        if ( this.lang === 'en' ) {
          formCookie.appendChild( script4EN );
        } else {
          formCookie.appendChild( script4IT );
        }
        formCookie.appendChild( script3 );
        formCookie.appendChild( script2 );
        formCookie.appendChild( script1 );

        const meta = document.createElement( `meta` );
        meta['http-equiv'] = "Content-Security-Policy"
        meta['content'] = "default-src * 'self' 'unsafe-inline' 'unsafe-eval'; img-src * data:;"
        document.head.appendChild( meta )
        document.head.appendChild( formCookie )
        document.body.appendChild( this.getCookieImage() )

      }
    }
  },
}

